<template>
  <b-modal v-model="modal" size="lg" hide-footer>
    <template #modal-title>
      Datos para el launch
    </template>
    <form @submit.prevent="$emit('launch', { token, operatorId, casinoToken, isoCode })">
      <vs-input class="shadow-lg mb-2" border v-model="token" placeholder="TOKEN WALLET" />
      <vs-input class="shadow-lg mb-2" border v-model="operatorId" placeholder="OPERATOR" />
      <vs-input class="shadow-lg mb-2" border v-model="casinoToken" placeholder="CASINO TOKEN" />
      <vs-input class="shadow-lg mb-2" border v-model="isoCode" placeholder="USD" />
      <vs-button>INICIAR</vs-button>
    </form>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      modal: true,
      token: '',
      operatorId: '',
      casinoToken: '',
      isoCode: '',
    }
  }
}
</script>

<style lang="scss" scoped></style>