<template>
  <b-modal id="bv-modal-example" v-model="modal" size="lg" hide-footer>
    <template #modal-title>Editar Dragon Tiger</template>
    <div>
      <form @submit.prevent="save()">
        <b-row>
          <b-col sm="12" md="6">
            <div class="mb-3">
              <label>Chance Simple</label>
              <vs-input v-model="typeform.chanceSimple" border class="shadow-lg" :class="{
                'is-invalid': typesubmit && $v.typeform.chanceSimple.$error,
              }" />
              <div v-if="typesubmit && $v.typeform.chanceSimple.$error" class="invalid-feedback">
                <span v-if="!$v.typeform.chanceSimple.required">
                  Este campo es requerido.
                </span>
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="6">
            <div class="mb-3">
              <label>Empate</label>
              <vs-input v-model="typeform.tie" border class="shadow-lg" :class="{
                'is-invalid': typesubmit && $v.typeform.tie.$error,
              }" />
              <div v-if="typesubmit && $v.typeform.tie.$error" class="invalid-feedback">
                <span v-if="!$v.typeform.tie.required">
                  Este campo es requerido.
                </span>
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="6">
            <div class="mb-3">
              <label>Empate perfecto</label>
              <vs-input v-model="typeform.perfectTie" border class="shadow-lg" :class="{
                'is-invalid': typesubmit && $v.typeform.perfectTie.$error,
              }" />
              <div v-if="typesubmit && $v.typeform.perfectTie.$error" class="invalid-feedback">
                <span v-if="!$v.typeform.perfectTie.required">
                  Este campo es requerido.
                </span>
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="6">
            <div class="mb-3">
              <label>Color</label>
              <vs-input v-model="typeform.color" border class="shadow-lg" :class="{
                'is-invalid': typesubmit && $v.typeform.color.$error,
              }" />
              <div v-if="typesubmit && $v.typeform.color.$error" class="invalid-feedback">
                <span v-if="!$v.typeform.color.required">
                  Este campo es requerido.
                </span>
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="6">
            <div class="mb-3">
              <label>Tamaño</label>
              <vs-input v-model="typeform.size" border class="shadow-lg" :class="{
                'is-invalid': typesubmit && $v.typeform.size.$error,
              }" />
              <div v-if="typesubmit && $v.typeform.size.$error" class="invalid-feedback">
                <span v-if="!$v.typeform.size.required">
                  Este campo es requerido.
                </span>
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="6">
            <div class="mb-3">
              <label>Paridad</label>
              <vs-input v-model="typeform.parity" border class="shadow-lg" :class="{
                'is-invalid': typesubmit && $v.typeform.parity.$error,
              }" />
              <div v-if="typesubmit && $v.typeform.parity.$error" class="invalid-feedback">
                <span v-if="!$v.typeform.parity.required">
                  Este campo es requerido.
                </span>
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="6">
            <div class="mb-3">
              <label>Jackpot</label>
              <div>
                <vs-input v-model="typeform.jackpot" border class="shadow-lg" :class="{
                  'is-invalid': typesubmit && $v.typeform.jackpot.$error,
                }" />
                <div v-if="typesubmit && $v.typeform.jackpot.$error" class="invalid-feedback">
                  <span v-if="!$v.typeform.jackpot.required">
                    Este campo es requerido.
                  </span>
                </div>
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="6">
            <div class="mb-3">
              <label>K Dorada</label>
              <div>
                <vs-input v-model="typeform.goldenK" border class="shadow-lg" :class="{
                  'is-invalid': typesubmit && $v.typeform.goldenK.$error,
                }" />
                <div v-if="typesubmit && $v.typeform.goldenK.$error" class="invalid-feedback">
                  <span v-if="!$v.typeform.goldenK.required">
                    Este campo es requerido.
                  </span>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <div class="mb-3 mb-0">
          <div class="d-flex">
            <vs-button type="submit">Guardar</vs-button>
            <vs-button @click="closeModa()" type="button" success>
              Cancelar
            </vs-button>
          </div>
        </div>
      </form>
    </div>
  </b-modal>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import Swal from "sweetalert2";

export default {
  props: {
    operator: {
      type: Object,
      default: () => {
        return {};
      },
    },
    game: {
      typeof: {},
      default: () => {
        return {}
      },
    },
  },
  computed: {
    ...mapGetters({
      success: 'operators/getSuccess'
    })
  },
  data() {
    return {
      submit: false,
      typesubmit: false,
      modal: false,
      typeform: {}
    };
  },
  validations: {
    typeform: {
      chanceSimple: {
        required
      },
      tie: {
        required,
      },
      perfectTie: {
        required,
      },
      size: {
        required,
      },
      color: {
        required,
      },
      parity: {
        required,
      },
      jackpot: {
        required,
      },
      goldenK: {
        required,
      },
    },
  },
  methods: {
    async save() {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) return console.log("error");
      const { uuid: gameUuid, chanceSimple, tie, perfectTie, jackpot, goldenK, size, color, parity } = this.typeform;
      const { uuid } = this.operator;
      const dataToUpdate = { chanceSimple, tie, perfectTie, jackpot, goldenK, size, color, parity };
      try {
        await this.editPayments({ gameUuid, uuid, dataToUpdate });

        if (!this.success) return Swal.fire("Error editando operador", "", "error")

        Swal.fire({
          position: "center",
          icon: "success",
          title: "Dragon tiger actualizado con exito",
          showConfirmButton: false,
          timer: 1500
        })

        this.closeModa();

      } catch (error) {
        console.log("ERROR EDIT OPERATOR", error)
      }

    },
    closeModa() {
      this.$emit("closeModal");
      this.modal = false;
    },
    ...mapActions({
      editPayments: 'operators/editPayments'
    })
  },
  watch: {
    game(newData) {
      if (!newData) return
      this.typeform = { ...newData };
    }
  }
};
</script>
