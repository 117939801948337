<template>
  <b-modal id="game-modal-menu" v-model="modal" size="md" hide-footer centered>
    <h2 class="text-center">{{ $t("sidebar.games.text").toUpperCase() }}</h2>
    <div class="menu-container mt-4">
      <vs-button v-for="(game, i) in gameList" :key="i" @click="handleClick(game)">
        {{ game.name }}
      </vs-button>
    </div>
  </b-modal>
</template>

<script>

import { gameList } from '@/helpers/gameList.js'

export default {
  props: ["model", "isAdding"],
  data() {
    return {
      modal: false,
      gameList
    }
  },
  methods: {
    handleClick(game) {
      this.$emit("openListGame", { game, model: this.model, isAdding: this.isAdding })
    },
  }
}
</script>

<style scoped>
.menu-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(auto-fit, 50px);
}
</style>