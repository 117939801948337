<template>
  <vs-button secondary class="d-flex justify-content-center">
    <router-link :to="route" class="text-light">
      <i class="uil-exchange"></i>
      {{ `${$t('helpers.goTo')} ${destiny}` }}
    </router-link>
  </vs-button>
</template>

<script>
export default {
  props: ["destiny", "route"],
  name: "ChangeView"
}
</script>