<template>
  <b-modal id="bv-modal-example" v-model="modal" size="md" hide-footer centered>
    <b-row>
      <b-col>
        <vs-button type="button"
          @click="handleOption('default')"
          value="default">
          Configuracion por defecto
        </vs-button>
      </b-col>
      <b-col>
        <vs-button type="button"
          @click="handleOption('custom')"
          value="custom">
          Configuracion personalizada
        </vs-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
export default {
  props: {
    operator: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      modal: false,
    };
  },
  methods: {
    closeModa() {
      this.modal = false;
    },
    edit(item) {
      this.roulette = item;
      this.$refs.EditRoulette.modal = true;
    },
    handleOption(selection) {
      if(selection === 'default') {
        this.$emit('handleConfigChips', {
          operator: this.operator,
          defaultValues: true
        });
      }else {
        this.$emit('handleConfigChips', {
          operator: this.operator,
          defaultValues: false
        });
      }

    }
  },
};
</script>