<template>
  <div>
    <template v-if="jackpot instanceof Array">
      <span v-for="(jack, i) in jackpot" :key="i" class="position-relative">
        <span :class="`ms-3 fw-bolder jacks p-1 ${colorResult(jack.number)}`">{{ result(jack.number) }}</span>
        <span class="badge badge-jack bg-warning rounded-pill">X{{ jack.multiplier }}</span>
      </span>
    </template>
    <span v-else class="position-relative">
      <span :class="`ms-3 fw-bolder jacks p-1`">{{ jackpot.number }}</span>
      <span class="badge badge-jack bg-warning rounded-pill">X{{ jackpot.multiplier }}</span>
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: ['jackpot'],
  computed: {
    ...mapGetters({
      result: 'reports/formatRouletteResults',
      colorResult: 'reports/colorResult',
    })
  },
}
</script>

<style lang="scss" scoped>
.black {
  background: #000;
  color: #fff;
  padding: 4px;
  text-align: center;
}

.red {
  background: #f10;
  color: #fff;
  padding: 4px;
  text-align: center;
}

.green {
  background: #00a429;
  color: #fff;
  padding: 4px;
  text-align: center;
}

.jacks {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.badge-jack {
  position: absolute;
  top: -12px;
  right: -14px;
  z-index: 9;
}
</style>