<template>
  <b-modal id="bv-modal-example" v-model="modal" size="lg" hide-footer>
    <template #modal-title> Agregar Juego </template>
    <div>
      <form @submit.prevent="save()">
        <div class="mb-3 mx-auto">
          <multiselect v-model="value" label="name" track-by="name" :options="games" @tag="addTag">
          </multiselect>
        </div>

        <div class="d-flex">
          <vs-button type="submit">Guardar</vs-button>
          <vs-button @click="closeModa()" type="button" success>
            Cancelar
          </vs-button>
        </div>

      </form>
    </div>
  </b-modal>
</template>

<script>
import Swal from 'sweetalert2';
import { mapActions, mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect'

export default {
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      },
    },
    games: {
      typeof: Array,
      default: () => {
        return [];
      },
    },
    path: {
      typeof: String,
      default: () => ''
    }
  },
  components: { Multiselect },
  data() {
    return {
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      modal: false,
      typeform: {},
      value: null,
    };
  },
  computed: {
    ...mapGetters({
      success: 'clients/isSuccess'
    })
  },
  methods: {
    addTag(newTag) {
      const tag = newTag

      this.value.push(tag)
    },
    async save() {
      this.typesubmit = true;
      const gameUuid = this.value.uuid;

      await this.addDragonTiger({ route: this.path, gameUuid })

      if (!this.success) return Swal.fire("Error adding game", "", "info");

      Swal.fire({
        position: "center",
        icon: "success",
        title: `${this.value.name} agregado con exito`.toUpperCase(),
        showConfirmButton: false,
        timer: 1500,
      });
      this.closeModa();
    },
    defaultValue(value) {
      let roundDuration =
        value.target.options[value.target.options.selectedIndex].getAttribute(
          "data-roundDuration"
        );

      this.typeform.roundDuration = roundDuration;
    },
    closeModa() {
      this.typeform = {};
      this.modal = false;
      this.$emit("closeGameMenu");
    },
    ...mapActions({
      addDragonTiger: 'clients/addDragonTiger'
    })
  },
};
</script>
