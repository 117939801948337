<template>
  <b-modal id="bv-modal-example" v-model="modal" size="xl" hide-footer centered>
    <template #modal-title>EXTERNAL GAMES: {{ client.name }} </template>
    <div>
      <div>
        <div>
          <b-table striped hover :items="games" :fields="fields">
            <template #cell(actions)="data">
              <b-dropdown variant="link" no-caret>
                <template #button-content>
                  <i class="fa fa-fw fa-bars font-size-16" />
                </template>
                <b-dropdown-item @click="deleteGame(data.item)">
                  <i class="uil-trash m-2 font-size-18" />
                  <span class="align-middle ml-5">Eliminar</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </div>
      </div>
    </div>
    <!-- <EditRoulette :roulette="roulette" :client="item" ref="EditRoulette" /> -->
  </b-modal>
</template>

<script>
import Swal from 'sweetalert2';
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    games: {
      type: Array,
    },
    client: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      modal: false,
      roulette: {},
      fields: [
        {
          key: "name",
          label: "EXTERNAL GAME",
          class: "text-center",
        },
        {
          key: "productId",
          label: "TYPE",
          class: "text-center",
        },
        {
          key: 'actions',
          label: 'ACCIONES',
          class: "text-center",
        }
      ],
    };
  },
  computed: {
    ...mapGetters({
      success: 'games/isSuccess'
    })
  },
  methods: {
    closeModa() {
      this.modal = false;
    },
    edit(item) {
      this.roulette = item;
      this.$refs.EditRoulette.modal = true;
    },
    async deleteGame(game) {

      try {
        const { isConfirmed } = await Swal.fire('Estas seguro en eliminar este juego?', '', 'question');

        if (!isConfirmed) return

        await this.removeGame({ id: game.ref });

        if (!this.success) return Swal.fire('Error eliminando el juego', '', 'error');

        Swal.fire('Juego eliminado', '', 'success');
        this.closeModa()
      } catch (error) {
        console.log('ERROR DELETE GAME', error);
      }
    },
    ...mapActions({
      removeGame: 'games/deleteExternalGame'
    })
  },
};
</script>